<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'projectPageGoods',
  data() {
    return {
      ...api.command.getState(),
      commodityList: []
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmShowSpecialProduct/page',
      current: 1,
      paramsValue: {
        configId: this.$route.query.configId
      }
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          label: '',
          type: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'productName',
          title: '特产名称',
          align: 'left',
          customRender: (text, records) => {
            let aImg = records.bannerUrl?.split(',') || []
            return (
              <div>
                <div class="product-order-item">
                  {aImg.map((item, index) => {
                    return <img src={item} key={index} />
                  })}
                  <div>{records.productName}</div>
                </div>
              </div>
            )
          }
        },
        {
          dataIndex: 'configName',
          title: '分类',
          align: 'left',
          width: '40%'
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => {
                  let obj = Object.assign({}, records)
                  this.handlePopup(obj)
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: '/farmShowSpecialProduct',
                    params: {
                      id: records.id
                    }
                  })
                }
              }
            ]
          }
        }
      ]
    },
    async onMainBodySearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmNativeProduct/list?name=${value}`
      })
      this.commodityList = searchResult.map(el => {
        return {
          name: el.name,
          value: el.id
        }
      })
    },
    getDrawerForm(source = { sort: 50 }, formData) {
      const form = [
        {
          form: [
            {
              name: '商品名称',
              labelAlign: 'right',
              type: 'dropDownInput',
              typeData: this.commodityList,
              key: 'productName',
              valueKey: 'productId',
              inputProps: {
                addonBefore: <a-icon type="search" />
              },
              onInputSearch: this.onMainBodySearch,
              rules: [
                {
                  required: true,
                  type: 'string'
                }
              ]
            },
            {
              name: '排序号（默认50）',
              key: 'sort',
              labelAlign: 'right',
              type: 'inputNumber'
            }
          ]
        }
      ]
      return form
    },
    handlePopup(
      dataSource = {
        sort: 50
      }
    ) {
      let that = this
      apiTool.showDrawer({
        title: '设置',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: formData => that.getDrawerForm(dataSource, formData),
          form: dataSource
          //   formProps: {
          //     labelCol: { span: 3 },
          //     wrapperCol: { span: 20 }
          //   }
        },
        success: ({ data, setHidden }) => {
          data.productId = this.commodityList.find(el => el.name === data.productName)?.value || ''
          if (!(data.productName && data.productId)) {
            this.$message.error('请选择商品')
            return
          } else {
            api.command[data.id ? 'edit' : 'create']
              .call(that, {
                url: '/farmShowSpecialProduct',
                params: { ...data, configId: this.$route.query.configId }
              })
              .then(setHidden)
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.handlePopup()
          }
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
